const COOKIE_PAGE = '/cookie-policy'

exports.onClientEntry = () => {
  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement('img')
  if (
    typeof testImg.style.objectFit === 'undefined' ||
    typeof testImg.style.objectPosition === 'undefined'
  ) {
    import('object-fit-images').then(({ default: ObjectFitImages }) => {
      ObjectFitImages()
      console.log('👍 Object-fit/Object-position are polyfilled')
    })
  }
}

exports.onRouteUpdate = ({ location }) => {
  const isCookiePage = location.pathname.startsWith(COOKIE_PAGE)

  if (isCookiePage) {
    if (window.sessionStorage.entered !== 'true') {
      window.location.reload()
      sessionStorage.setItem('entered', 'true')
    }
  } else {
    sessionStorage.setItem('entered', 'false')
  }
}
